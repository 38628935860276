<template>
  <div class="app-list">
    <div class="app-list-bd" style="overflow: auto">
      <div class="app-list-tl">
        <span>大屏列表</span>
        <div style="display: flex; align-items: center">
          <div class="mode-lib" @click="mode_dialog_visible = true">
            <i class="dlh-danjumoban-icon"></i> 模版库
          </div>
          <div
            class="dlh-primary-btn"
            v-show="Manage"
            @click.stop="setAction('new', { id: 'undefined' })"
          >
            新建
          </div>
        </div>
      </div>
      <div>
        <div class="app-list-ul" v-show="p_list.length > 0">
          <div
            class="app-list-li"
            style="cursor: pointer"
            v-for="(item, index) in p_list"
            :key="index"
            @click="openLink(item)"
          >
            <img
              v-if="item.template == 1"
              src="../assets/mode13.png"
              alt=""
              style="height: 121px; width: 100%; cursor: pointer"
            />
            <img
              v-else
              src="../assets/mode23.png"
              alt=""
              style="height: 121px; width: 100%; cursor: pointer"
            />
            <div class="alu-title">
              <span v-if="item.name.length < 10">{{ item.name || "" }}</span>
              <el-tooltip v-else :content="item.name" placement="top">
                <div class="one-line">{{ item.name }}</div>
              </el-tooltip>
            </div>
            <i
              class="three-doll"
              @click.stop="showAction(item)"
              v-if="Manage"
            >
              <svg
                width="4px"
                height="16px"
                viewBox="0 0 4 16"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <title>ellipsis</title>
                <g
                  id="页面-1"
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <g
                    id="大屏列表"
                    transform="translate(-570.000000, -124.000000)"
                    fill="#6A6D70"
                  >
                    <g
                      id="大屏列表/卡片备份-14"
                      transform="translate(377.000000, 112.000000)"
                    >
                      <g
                        id="编组-2备份-2"
                        transform="translate(183.000000, 8.000000)"
                      >
                        <g
                          id="ellipsis"
                          transform="translate(12.000000, 12.000000) rotate(-270.000000) translate(-12.000000, -12.000000) translate(4.000000, 10.000000)"
                        >
                          <path
                            d="M0.4,1.99999996 C0.4,2.60735148 0.724018154,3.16856742 1.24999999,3.47224318 C1.77598183,3.77591894 2.42401817,3.77591894 2.95000001,3.47224318 C3.47598185,3.16856742 3.8,2.60735148 3.8,1.99999996 C3.8,1.39264845 3.47598185,0.831432507 2.95000001,0.527756748 C2.42401817,0.224080989 1.77598183,0.224080989 1.24999999,0.527756748 C0.724018154,0.831432507 0.4,1.39264845 0.4,1.99999996 L0.4,1.99999996 Z"
                            id="路径"
                          ></path>
                          <path
                            d="M6.343182,2 C6.343182,2.93888407 7.10429793,3.7 8.043182,3.7 C8.98206607,3.7 9.743182,2.93888407 9.743182,2 C9.743182,1.06111593 8.98206607,0.3 8.043182,0.3 C7.10429793,0.3 6.343182,1.06111593 6.343182,2 Z"
                            id="路径"
                          ></path>
                          <path
                            d="M12.286364,2 C12.286364,2.93888407 13.0474799,3.7 13.986364,3.7 C14.9252481,3.7 15.686364,2.93888407 15.686364,2 C15.686364,1.06111593 14.9252481,0.3 13.986364,0.3 C13.0474799,0.3 12.286364,1.06111593 12.286364,2 Z"
                            id="路径"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </i>
            <div class="action-box" v-show="item.show_edit">
              <div @click.stop="setAction('set', item)">设置</div>
              <div @click.stop="setAction('copy', item)">复制</div>
              <div @click.stop="setAction('del', item)">删除</div>
            </div>
            <div class="time-info">
              <span>最近修改时间：</span>
              <div style="color: rgba(0, 0, 0, 0.65)">
                {{ item.update_time || "" }}
              </div>
            </div>
          </div>
        </div>
        <div
          v-show="p_list.length == 0"
          style="display: block; margin: 100px auto; width: 200px"
        >
          <img
            style="height: 200px; width: 200px"
            :src="require('../assets/nodata.png')"
            alt=""
          />
          <div style="text-align: center; margin-top: -65px">暂无数据</div>
        </div>
      </div>
    </div>

    <!-- 模版库弹窗 -->
    <el-dialog
      title="模版库"
      :visible.sync="mode_dialog_visible"
      :modal-append-to-body="true"
      :append-to-body="true"
      lock-scroll
      width="418px"
      top="30vh"
      class="app-list-dia mode-dia"
    >
      <div class="mode-dia-header">
        <span
          @click="active_mode = 1"
          :class="{ 'active-mode': active_mode == 1 }"
          >企业介绍</span
        >
        <span
          @click="active_mode = 2"
          :class="{ 'active-mode': active_mode == 2 }"
          >项目介绍</span
        >
      </div>
      <div class="mode-dia-body" v-show="active_mode == 1">
        <div class="mode-dia-li" @click="openModeLink(2)">
          <img src="../assets/mode11.png" alt="" />
          <div class="previe-text">模版 1（偏公司）</div>
          <div class="previe-btn btn-linebtn">预 览</div>
        </div>
        <div class="mode-dia-li" @click="openModeLink(0)">
          <img src="../assets/Rectangle861_1.png" alt="" />
          <div class="previe-text">模版 2（偏业务）</div>
          <div class="previe-btn btn-linebtn">预 览</div>
        </div>
      </div>
      <div class="mode-dia-body" v-show="active_mode == 2">
        <div class="mode-dia-li" @click="openModeLink(1)">
          <img src="../assets/Rectangle861_4.png" alt="" />
          <div class="previe-text">模版 1</div>
          <div class="previe-btn btn-linebtn">预 览</div>
        </div>
        <div class="mode-dia-li" @click="openModeLink(3)">
          <img src="../assets/Rectangle861_3.png" alt="" />
          <div class="previe-text">模版 2</div>
          <div class="previe-btn btn-linebtn">预 览</div>
        </div>
      </div>

      <span slot="footer" class="app-dialog-footer"> </span>
    </el-dialog>

    <!-- :before-close="handleClose" -->
    <el-dialog
      :title="dialog_title"
      :visible.sync="dialog_visible"
      :close-on-click-modal="false"
      :modal-append-to-body="true"
      :append-to-body="true"
      lock-scroll
      width="418px"
      top="30vh"
      class="app-list-dia"
    >
      <div v-if="dialog_title == '新建大屏'" style="margin-bottom: 6px">
        大屏名称
      </div>
      <span v-if="action_type != 'set' && action_type != 'new'">{{
        dialog_info
      }}</span>
      <span v-else style="position: relative">
        <el-input
          v-model="scree_name"
          @change="formatTitle"
          :class="{ errorbd: !scree_name && sub_mode }"
          placeholder="输入大屏名称，30字内 "
          maxlength="30"
        ></el-input>
        <div
          class="error-txt"
          style="color: #f85f64; position: absolute; left: 10px"
          v-if="!scree_name && sub_mode"
        >
          大屏名称不得为空
        </div>
      </span>
      <div
        v-if="dialog_title == '新建大屏'"
        style="margin-bottom: 10px; margin-top: 20px"
      >
        选择模板
      </div>
      <div class="dialog-body" v-if="dialog_title == '新建大屏'">
        <div class="model">
          <div
            class="left"
            :class="{ border: show_select == 1 }"
            @click="select_model1"
          >
            <div class="left_picture">
              <img src="../assets/mode11.png" alt="" />
            </div>
            <div class="left_name">模板 1（偏公司）</div>
            <img
              v-if="show_select == 1"
              class="left_select_img"
              src="../components/projectinfo/img/select_model.png"
              alt=""
            />
          </div>
          <div
            class="right"
            :class="{ border: show_select == 2 }"
            @click="select_model2"
          >
            <div class="right_picture">
              <img src="../assets/Rectangle861_1.png" alt="" />
            </div>
            <div class="right_name">模板 2（偏业务）</div>
            <img
              v-if="show_select == 2"
              class="right_select_img"
              src="../components/projectinfo/img/select_model.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <span slot="footer" class="app-dialog-footer">
        <span class="cancel-btn" @click="cancelClic">取消</span>
        <span class="submit-btn" type="primary" @click="submitClic">确认</span>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { screeninter } from "../api/getdata";
export default {
  props: {
    user_auths: {},
  },
  // 取消查看权限判断09-07
  // watch:{
  //   user_auths(){
  //     if(JSON.stringify(this.user_auths) != '{}'){
  //       this.initList();
  //     }
  //   }
  // },
  data() {
    return {
      p_list: [],
      active_item: {},
      action_type: "",
      dialog_title: "提示",
      mode_dialog_visible: false,
      dialog_info: "",
      dialog_visible: false,
      scree_name: "",
      sub_mode: false,
      action_dis: false,
      active_mode: 1,
      show_select: 1, //控制显示地模板
    };
  },
  methods: {
    select_model1() {
      this.show_select = 1;
    },
    select_model2() {
      this.show_select = 2;
    },
    showAction(item) {
      this.$set(this.active_item, "show_edit", false);
      this.active_item = item;
      if (item.show_edit === "undefind") {
        this.$set(item, "show_edit", true);
      } else {
        if (item.show_edit) {
          this.$set(item, "show_edit", false);
        } else {
          this.$set(item, "show_edit", true);
        }
      }
    },
    closeAtion() {
      this.$set(this.active_item, "show_edit", false);
    },
    setAction(item, it) {
      this.$set(it, "show_edit", false);
      this.active_item = it;
      this.action_type = item;
      if (item === "set") {
        this.scree_name = it.name;
        this.dialog_title = "设置大屏名称";
      } else if (item === "new") {
        this.scree_name = it.name;
        this.dialog_title = "新建大屏";
      } else if (item === "del") {
        this.dialog_title = "提示";
        this.dialog_info = "删除当前大屏后数据无法找回，是否确认删除？";
      } else if (item === "copy") {
        this.dialog_title = "提示";
        this.dialog_info =
          "复制大屏将自动创建与当前大屏数据完全相同的副本，是否确认执行？";
      }
      this.dialog_visible = true;
    },
    openLink(item) {
      let template = item.template;
      console.log(location.pathname);

      let guidMini = location.pathname?.split("/")[1] || "";
      if (guidMini === "applist.html" || guidMini === "home.html#/") {
        guidMini = "";
      }
      let windowHref = "/";

      if (guidMini) {
        if (template === 2) {
          windowHref = "/" + guidMini + "/home.html#/?id=" + item.id;
        } else {
          windowHref = "/" + guidMini + "/home.html#/newhome?id=" + item.id;
        }
      } else {
        if (template === 2) {
          windowHref = "/home.html#/?id=" + item.id;
        } else {
          windowHref = "/home.html#/newhome?id=" + item.id;
        }
      }
      window.open(windowHref, "_blank");
      // location.href = windowHref;
    },
    initList() {
      screeninter("list", { page_no: 1, page_size: 1000000 }).then((ele) => {
        this.p_list = ele.data?.page_data || [];
      });
    },
    cancelClic() {
      this.dialog_visible = false;
      this.scree_name = "";
      this.sub_mode = false;
    },
    submitClic() {
      if (this.action_dis) {
        return;
      }
      this.action_dis = true;
      setTimeout(() => {
        this.action_dis = false;
      }, 1500);
      this.dialog_visible = false;
      if (this.active_item.id === "undefined") {
        if (!this.scree_name) {
          this.$message.error({
            showClose: true,
            message: "失败：大屏名称不得为空",
          });
          this.dialog_visible = true;
          this.sub_mode = true;
          return;
        }
        screeninter("add", {
          name: this.scree_name,
          template: this.show_select,
        }).then((ele) => {
          this.$message.success({
            showClose: true,
            message: "成功：操作成功",
          });
          this.initList();
        });
        return;
      }
      if (this.action_type === "set") {
        if (!this.scree_name) {
          this.$message.error({
            showClose: true,
            message: "失败：大屏名称不得为空",
          });
          this.dialog_visible = true;
          this.sub_mode = true;
          return;
        }
        screeninter("set", {
          id: Number(this.active_item.id),
          name: this.scree_name,
        }).then((ele) => {
          this.$message.success({
            showClose: true,
            message: "成功：操作成功",
          });
          this.initList();
          this.dialog_visible = false;
        });
      } else if (this.action_type === "del") {
        screeninter("delete", { id: Number(this.active_item.id) }).then(
          (ele) => {
            this.$message.success({
              showClose: true,
              message: "成功：操作成功",
            });
            this.initList();
            this.dialog_visible = false;
          }
        );
      } else if (this.action_type === "copy") {
        screeninter("copy", { id: Number(this.active_item.id) }).then((ele) => {
          this.$message.success({
            showClose: true,
            message: "成功：操作成功",
          });
          this.initList();
          this.dialog_visible = false;
        });
      }
    },
    formatTitle(val) {
      this.scree_name = val.replace(/(\s*$)/g, "");
    },
    openModeLink(id) {
      let guidMini = location.pathname?.split("/")[1] || "";
      if (guidMini === "applist.html" || guidMini === "home.html#/") {
        guidMini = "";
      }
      let windowHref = "/";
      if (guidMini) {
        windowHref = "/" + guidMini + "/home.html#/mode?id=" + id;
      } else {
        windowHref = "/home.html#/mode?id=" + id;
      }
      window.open(windowHref, "_blank");
      // location.href = windowHref;
    },
  },
  mounted() {
    this.initList();
    document.addEventListener("click", () => {
      this.$set(this.active_item, "show_edit", false);
    });
  },
};
</script>

<style lang="less">
.app-list {
  background-color: #fff;
  width: 1350px;
  // height: 1080px;
  margin-top: 108px;
  position: absolute;
  // overflow-y: auto;
  .app-list-bd {
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .app-list-tl {
    height: 60px;
    display: flex;
    padding: 0 96px;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 48px;
    width: 1158px;
    z-index: 1;
    background: #fff;
    span {
      font-size: 16px;
      font-weight: 700;
      color: #000000;
    }
    .mode-lib {
      width: 80px;
      height: 30px;
      line-height: 32px;
      opacity: 1;
      border: 1px solid #0a6ed1;
      border-radius: 2px;
      color: #0a6ed1;
      font-size: 14px;
      cursor: pointer;
      margin-right: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        opacity: 0.85;
      }
      .dlh-danjumoban-icon {
        display: inline-block;
        width: 16px;
        height: 16px;
        background-image: url("../assets/modeicon.svg");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        margin-right: 4px;
      }
    }
  }
  &::-webkit-scrollbar {
    display: none;
  }
  .app-list-ul {
    width: 1350px;
    padding: 0 92px;
    padding-bottom: 150px;
  }
  .app-list-li {
    width: 214px;
    height: 233px;
    display: inline-block;
    opacity: 1;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
    // margin-right: 26px;
    margin-bottom: 24px;
    position: relative;
    &:not(:nth-child(5n)) {
      margin-right: 24px;
    }
    .three-doll {
      display: inline-block;
      height: 24px;
      width: 24px;
      text-align: center;
      padding-top: 2px;
      box-sizing: border-box;
      background-color: #fff;
      border-radius: 4px;
      // background-image: url("../assets/threedoll.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
      &:hover {
        opacity: 0.85;
      }
      &:active {
        opacity: 0.9;
      }
    }
    .action-box {
      width: 60px;
      height: 90px;
      padding: 2px 0;
      text-align: center;
      opacity: 1;
      background: #ffffff;
      border-radius: 2px;
      backdrop-filter: blur(5px);
      position: absolute;
      right: 10px;
      top: 44px;
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
      div {
        height: 30px;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.85);
        line-height: 30px;
        cursor: pointer;
        &.active {
          background: #057df5;
          color: #fff;
        }
        &:hover {
          background: #057df5;
          color: #fff;
        }
      }
    }
    .alu-title {
      width: 194px;
      height: 20px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      // font-size: 16px;
      font-size: 14px;
      line-height: 20px;
      font-family: PingFangSC, PingFangSC-Semibold;
      font-weight: 600;
      text-align: left;
      color: rgba(0, 0, 0, 0.85);
      line-height: 22px;
      overflow: hidden;
      margin: 12px 10px 19px 10px;
    }
  }
  .li-img:nth-child(4n) {
    margin-right: 0;
  }
  .time-info {
    font-size: 14px;
    padding: 0 10px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.45);
    line-height: 22px;
    // display: flex;
    // justify-content: space-between;
  }
}
body,
html {
  background-color: #e3e3e3;
  // overflow-x: hidden;
}
.mode-dia {
  .el-dialog__headerbtn .el-dialog__close {
    color: rgba(51, 51, 51, 1);
    font-weight: 700;
  }
  .mode-dia-header {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #bcbdbf;
    margin-bottom: 20px;
    margin-top: -9px;
    span {
      display: inline-block;
      height: 34px;
      line-height: 34px;
      width: 88px;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.85);
      cursor: pointer;
      text-align: center;
    }
    .active-mode {
      color: #0a6ed1;
      font-weight: 600;
      &::after {
        content: "";
        display: block;
        height: 2px;
        width: 54px;
        background: #0a6ed1;
        position: relative;
        top: -1px;
        left: 18px;
      }
    }
  }
  .mode-dia-body {
    display: flex;
    justify-content: space-between;
    .mode-dia-li {
      width: 160px;
      height: 150px;
      padding: 10px 8px 0 8px;
      opacity: 1;
      background: #ffffff;
      border-radius: 6px;
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
      img {
        width: 161px;
        height: 90px;
      }
      .previe-text {
        height: 56px;
        line-height: 56px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.85);
        font-size: 16px;
      }
      .previe-btn {
        display: none;
        width: 127px;
        height: 32px;
        line-height: 32px;
        color: #0a6ed1;
        text-align: center;
        margin-top: 11px;
        opacity: 1;
        border: 1px solid #0a6ed1;
        border-radius: 2px;
        cursor: pointer;
      }
      &:hover {
        .previe-text {
          display: none;
        }
        .previe-btn {
          display: block;
        }
      }
    }
  }
}
.app-list-dia {
  .el-dialog__title {
    font-size: 16px;
    font-weight: 600;
  }
  .el-dialog__header {
    padding: 16px 24px;
  }
  .el-dialog {
    border-radius: 10px;
  }
  .el-dialog .el-dialog__body {
    flex: 1;
    overflow: visible;
    padding: 11px 24px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 20px;
    .model {
      width: 100%;
      display: flex;
      justify-content: space-between;
      > div {
        width: 177px;
        height: 160px;
        opacity: 1;
        background: #ffffff;
        border-radius: 6px;
        overflow: hidden;
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
      }
      .left {
        box-sizing: border-box;
        position: relative;
        &:hover {
          border: 2px solid #129bff;
        }
        .left_picture {
          width: 100%;
          height: 104px;
          box-sizing: border-box;
          padding: 10px 8px 4px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .left_name {
          height: 56px;
          line-height: 56px;
          background: #f8f9fa;
          box-sizing: border-box;
          padding-left: 10px;
          font-size: 16px;
          font-family: PingFang SC, PingFang SC-Medium;
          font-weight: 600;
          text-align: LEFT;
          color: rgba(0, 0, 0, 0.85);
        }
        .left_select_img {
          width: 30px;
          height: 30px;
          position: absolute;
          bottom: -2px;
          right: -2px;
        }
      }
      .right {
        position: relative;
        box-sizing: border-box;
        &:hover {
          border: 2px solid #129bff;
        }
        .right_picture {
          width: 100%;
          height: 104px;
          box-sizing: border-box;
          padding: 10px 8px 4px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .right_name {
          height: 56px;
          line-height: 56px;
          background: #f8f9fa;
          box-sizing: border-box;
          padding-left: 10px;
          font-size: 16px;
          font-family: PingFang SC, PingFang SC-Medium;
          font-weight: 600;
          text-align: LEFT;
          color: rgba(0, 0, 0, 0.85);
        }
        .right_select_img {
          width: 30px;
          height: 30px;
          position: absolute;
          bottom: -2px;
          right: -2px;
        }
      }
      .border {
        border: 2px solid #129bff;
      }
    }
  }
  .app-dialog-footer {
    span {
      display: inline-block;
      height: 32px;
      line-height: 32px;
      padding: 0 14px;
      cursor: pointer;
      &:hover {
        opacity: 0.85;
      }
    }
    .cancel-btn {
      color: #0854a1;
      margin-right: 8px;
      &:hover {
        background-color: #e6f7ff;
        opacity: 0.85;
      }
    }
    .submit-btn {
      color: #fff;
      background-color: #0a6ed1;
    }
  }
}
.el-message__icon {
  display: none;
}
.el-message {
  min-width: 609px;
  border-radius: 0;
  padding: 11px 16px;
  &.el-message--error {
    background: rgba(162, 0, 0, 1);
    color: #fff;
    border: none;
    .el-message__content {
      color: #fff;
    }
  }
  &.el-message--success {
    background: #107f3e;
    color: #fff;
    border: none;
    .el-message__content {
      color: #fff;
    }
  }
  &.el-message--info {
    background: #706e6b;
    color: #fff;
    border: none;
    .el-message__content {
      color: rgba(0, 0, 0, 0.85);
    }
  }
  .el-message__content {
    font-size: 16px;
    line-height: 16px;
  }
  .el-message__closeBtn.el-icon-close {
    font-size: 24px;
    color: #fff;
  }
}
.errorbd {
  .el-input__inner,
  .el-textarea__inner {
    border: 1px solid #f85f64;
  }
}
.btn-linebtn {
  padding: 0 16px;
  height: 32px;
  border: 1px solid #2f8cde;
  border-radius: 2px;
  &:hover {
    background-color: #e7f1fa;
  }
  &:active {
    background: rgba(10, 110, 209, 0.1);
    border: 1px solid #0050ab;
  }
}
@media screen and (max-width: 1350px) {
  .app-list {
    width: 100%;
  }
  header.header div.main-container {
    width: 1167px;
  }
  .app-list {
    .app-list-tl,
    .app-list-ul {
      width: 1167px;
      padding: 0;
      margin: 0 auto;
    }
    .app-list-tl {
      width: 1167px;
      padding: 0;
      margin: 0 auto;
      left: 0;
      right: 0;
    }
  }
}
@media screen and (max-width: 1165px) {
  .app-list {
    width: 100%;
  }
  header.header div.main-container {
    width: 966px;
  }
  .app-list {
    .app-list-tl,
    .app-list-ul {
      width: 966px;
      padding: 0;
      margin: 0 auto;
    }
  }
  .app-list .app-list-li:not(:nth-child(5n)) {
    margin-right: 0;
  }
  .app-list .app-list-li:not(:nth-child(4n)) {
    margin-right: 36px;
  }
}
@media screen and (max-width: 1000px) {
  .app-list {
    width: 100%;
  }
  header.header div.main-container {
    width: 886px;
  }
  .app-list {
    .app-list-tl,
    .app-list-ul {
      width: 886px;
      padding: 0;
      margin: 0 auto;
    }
  }
  .app-list .app-list-li:not(:nth-child(4n)) {
    margin-right: 10px;
  }
}
@media screen and (max-width: 907px) {
  .app-list {
    width: 100%;
  }
  header.header div.main-container {
    width: 682px;
  }
  .app-list {
    .app-list-tl,
    .app-list-ul {
      width: 682px;
      padding: 0;
      margin: 0 auto;
    }
  }
  .app-list .app-list-li:not(:nth-child(4n)) {
    margin-right: 0;
  }
  .app-list .app-list-li:not(:nth-child(3n)) {
    margin-right: 20px;
  }
}
@media screen and (max-width: 680px) {
  header.header div.main-container {
    width: 100%;
  }
  .app-list {
    .app-list-tl,
    .app-list-ul {
      width: 478px;
      padding: 0;
      margin: 0 auto;
    }
  }
  .app-list .app-list-li:not(:nth-child(3n)) {
    margin-right: 0;
  }
  .app-list .app-list-li:not(:nth-child(2n)) {
    margin-right: calc(100% - 428px);
  }
}
@media screen and (max-width: 500px) {
  header.header div.main-container {
    width: 100%;
  }
  .app-list {
    .app-list-tl,
    .app-list-ul {
      width: 90%;
      min-width: 438px;
      padding: 0;
      margin: 0 auto;
    }
  }
  .app-list .app-list-li:not(:nth-child(3n)) {
    margin-right: 0;
  }
  .app-list .app-list-li:not(:nth-child(2n)) {
    margin-right: calc(100% - 428px);
  }
}

// .app-list .app-list-li:not(:nth-child(4n)){

// }
.el-popup-parent--hidden .app-list {
  height: calc(100vh - 109px);
  overflow: hidden;
}
.el-dialog {
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /*height:600px;*/
  max-height: calc(100% -30px);
  max-width: calc(100% -30px);
}
.el-dialog .el-dialog__body {
  flex: 1;
  overflow: auto;
}
html,
.app-list-bd {
  &::-webkit-scrollbar {
    width: 0 !important;
  }
}
</style>
