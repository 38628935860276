
// 获取当前环境的url 并跳转到所提供的路由
export const getHost = (router) => {
  const protocol = window.location.protocol;
  let url = "";
  if (protocol == "http:") {
    url = protocol + "//www-test.jihuitech.com";
  } else {
    url = protocol + "//www.jihuitech.com";
  }
  if (router && router != "login") {
    const token = localStorage.getItem("token");
    url = url + "/#/" + router + "?pid=" + token;
  }
  return url;
};
