import { post } from "./axios.js";
//const baseUrl = "http://api.reigncn.test/";
const baseUrl = "/colorc/";

// console.log(process.env.VUE_APP_BUILD);
// switch (process.en.VUE_APP_TITLE) {
//   case "alpha":
//     baseUrl = "/"; //测试环境中的url
//     break;
//   case "production":
//     baseUrl = "/production/"; //生产环境url
//     break;
//   default:
//     baseUrl = "/jiu"; //本地的请求url
// }

// 获取授权上传文件
export function uploadfile(params) {
  return post(baseUrl + "upload/auth", params);
}
// 上传OSS
export function uploadoss(url, params) {
  return post(url, params);
}

// 导航栏信息
export function userinfo(path, params) {
  let param = {
    // 导航栏信息
    info: "navigation/detail",
  };
  const url = baseUrl + param[path];
  return post(url, params);
}
// 大屏列表
export function screeninter(path, params) {
  let param = {
    // 添加大屏
    add: "screen/add",
    // 大屏列表
    list: "screen/list",
    // 设置大屏
    set: "screen/set",
    // 复制大屏
    copy: "screen/copy",
    // 删除大屏
    delete: "screen/delete",
  };
  const url = baseUrl + param[path];
  return post(url, params);
}

// 大屏标题
export function titleinter(path, params) {
  let param = {
    // 标题详情
    detail: "title/detail",
    // 编辑标题
    update: "title/update",
  };
  const url = baseUrl + param[path];
  return post(url, params);
}

// 企业介绍
export function introduceinter(path, params) {
  let param = {
    // 获取详情
    detail: "introduce/detail",
    // 编辑标题
    update: "introduce/save",
  };
  const url = baseUrl + param[path];
  return post(url, params);
}

// 热力图表
export function heatmapinter(path, params) {
  let param = {
    // 获取详情
    detail: "heat-map/detail",
    // 保存数据
    update: "heat-map/save",
  };
  const url = baseUrl + param[path];
  return post(url, params);
}

// 河流图表
export function rivermapinter(path, params) {
  let param = {
    // 获取详情
    detail: "river-map/detail",
    // 保存数据
    update: "river-map/save",
  };
  const url = baseUrl + param[path];
  return post(url, params);
}

// 堆叠条形
export function heapmapinter(path, params) {
  let param = {
    // 获取详情
    detail: "heap-map/detail",
    // 保存数据
    update: "heap-map/save",
  };
  const url = baseUrl + param[path];
  return post(url, params);
}

// 项目图表
export function platforminter(path, params) {
  let param = {
    // 保存数据
    save: "platform-map/save",
    // 获取详情
    detail: "platform-map/detail",
    // 智慧运维项目列表
    platforms: "platform-map/platforms",
    
  };
  const url = baseUrl + param[path];
  return post(url, params);
}

// 平行坐标
export function parallelmapinter(path, params) {
  let param = {
    // 保存标题&数量
    update: "parallel-map/save-title",
    // 获取标题&数量
    tdetail: "parallel-map/title-detail",
    // 保存数据
    save: "parallel-map/save",
    // 获取详情
    detail: "parallel-map/detail",
  };
  const url = baseUrl + param[path];
  return post(url, params);
}

// 企业信息
export function enterpriseinter(path, params) {
  let param = {
    // 保存标题
    save: "enterprise/save-title",
    // 标题详情
    detail: "enterprise/title-detail",
    // 保存企业简介
    intsave: "enterprise/save-introduce",
    // 企业简介详情
    intdetail: "enterprise/introduce-detail",
    // 保存企业风采图片
    saveimages: "enterprise/save-images",
    // 企业风采图片详情
    imagesdetail: "enterprise/images-detail",
    // 保存政策管理
    savepolicy: "enterprise/save-policy",
    // 政策管理详情
    policydetail: "enterprise/policy-detail",
  };
  const url = baseUrl + param[path];
  return post(url, params);
}
// 项目详情
export function teamdetail(path, params) {
  let param = {
    // 保存图标数据
    save: "platform-detail/save",
    // 图表数据详情
    detail: "platform-detail/detail",
    // 保存图片
    save_image: "platform-detail/save-image",
    // 图片详情
    image_detail: "platform-detail/image-detail",
   
  };
  const url = baseUrl + param[path];
  return post(url, params);
}
// 退出登录
export function logout(path, params) {
  let param = {
    // 退出登录
    logout: "navigation/logout",
  };
  const url = baseUrl + param[path];
  return post(url, params);
}

// 项目详情（模板一）
export function template1(path, params) {
  let param = {
    // 设置模板
    templatesave: "pd-template/save",
    // 获取当前模板
    templatedetail:"pd-template/detail",
    // 保存项目图集
    imagesSave:"pd-images/save",
    // 图集详情
    imagesDetail:"pd-images/detail",
    // 保存单个展示图
    saveImage:"pd-images/save-image",
    // 单个展示图详情
    imageDetail:"pd-images/image-detail",
    // 保存项目概况
    pddescSave:"pd-desc/save",
    // 项目概况详情
    pdDescDetail:"pd-desc/detail",
    // 保存进出水质
    pdwaterSualitySave:"pd-water-quality/save",
    // 进出水质详情
    pdwaterQualityDetail:"pd-water-quality/detail",
    // 保存项目成本
    pdcostSave:"pd-cost/save",
    // 项目成本详情
    pdcostDetail:"pd-cost/detail"

  };
  const url = baseUrl + param[path];
  return post(url, params);
}

// 新版企业介绍1.0.4
export function newintroduce(path, params) {
  let param = {
    //保存企业名称
    companyname_save: "company-name/save",
    //获取企业名称
    companyname_detail: "company-name/detail",
    //获取企业画像
    companyportrait_detail: "company-portrait/detail",  
    //保存企业画像
    companyportrait_save: "company-portrait/save",
    //保存发展历程
    companydevelop_save: "company-develop/save",
    //获取发展历程
    companydevelop_detail: "company-develop/detail",  
    //新增详细模块
    companyintroduce_add: "company-introduce/add",  
    //企业介绍模块列表
    companyintroduce_list: "company-introduce/list",  
    //企业介绍模块编辑
    companyintroduce_update: "company-introduce/update",  
    //企业介绍模块详情
    companyintroduce_detail: "company-introduce/detail",  
    //企业介绍模块删除
    companyintroduce_delete: "company-introduce/delete",  
    //保存企业项目分布
    companyplatform_save: "company-platform/save",  
    //企业项目分布详情
    companyplatform_detail: "company-platform/detail",  
    
  };
  const url = baseUrl + param[path];
  return post(url, params);
}

