import axios from "axios";
// import store from "@/store";
import { Message } from "element-ui";
// let router = import("@/router");
import { getHost } from "@/constants/navigationJump.js";
function getQueryVariable(variable) {
  // var query = window.location.search.substring(1);
  var query = window.location.href.split("?")[1] || "";
  var vars = query?.split("&") || [];
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (pair[0] == variable) {
      return pair[1];
    }
  }
  return false;
}

// axios.defaults.baseURL = "/";
let guidMini = location.pathname?.split("/")[1] || "";
console.log(guidMini);
if (guidMini === "home.html" || guidMini === "index.html") {
  guidMini = "";
}
axios.defaults.headers.Guid = guidMini;
axios.defaults.headers.post["Content-Type"] = "application/json;charset=UTF-8";
axios.defaults.headers["X-Requested-With"] = "XMLHttpRequest";
axios.defaults.headers["Cache-Control"] = "no-cache";
axios.defaults.headers["pragma"] = "no-cache";

// let source = axios.CancelToken.source();

//请求添加token
let token = getQueryVariable("pid");
if (token) {
  localStorage.setItem("token", token);
  location.href = location.href.split("?")[0];
} else {
  token = localStorage.getItem("token");
}
let Screen = getQueryVariable("id");
if (Screen) {
  localStorage.setItem("Screen", Screen);
} else {
  Screen = localStorage.getItem("Screen");
}
// let token = localStorage.getItem("token")||getQueryVariable("pid");
let Project = localStorage.getItem("Project");

axios.interceptors.request.use(async request => {
  if (token) {
    request.headers["token"] = token;
  }
  if (Screen) {
    request.headers["Screen"] = Screen;
  }
  if (Project) {
    request.headers["Project"] = Project ? Project : "";
  }

  return request;
});

//切换页面取消请求
// axios.interceptors.request.use(request => {
//     request.cancelToken = source.token;
//     return request;
// });
// router.then(lib => {
//     lib.default.beforeEach((to, from, next) => {
//         source.cancel()
//         source = axios.CancelToken.source();
//         next()
//     })
// })

//登录过期跳转
// axios.interceptors.response.use(response => {
// let data = response.data;
// if (
//     [10002].includes(data.ret)
// ) {
//     router.then(lib => lib.default.push({ name: "login" })); // 跳转到登录页面
//     Message.warning(data.msg);
// }
// return response;
// })

//返回值解构
axios.interceptors.response.use(
  response => {
    let data = response.data || {};
    let isJson = (response.headers["content-type"] || "").includes("json");
    if (isJson) {
      if (data.code === 2000 || data.code === 6001 || data.code === 5001) {
        return Promise.resolve({
          data: data.data,
          msg: data.msg,
          code: data.code
        });
      } else {
        Message.error(data.msg, 5);
      }
      return Promise.reject(data.msg || "网络错误");
    } else {
      return data;
    }
  },
  err => {
    if (err.response) {
      let mes = err.response.data.msg || err || err.response.data || err.response.statusText;

      switch (err.response.status) {
        case 401:
          // 返回 401 清除token信息并跳转到登录页面

          // Message.error("登录失效", 5);
          localStorage.removeItem("token");
          let host = window.location.hostname;
          if (host === "localhost") {
            return false;
          }

          // 跳转到home的登录页
          setTimeout(() => {
            location.href = getHost("login");
          }, 500);
          break;
        case 403:
          Message.error(err.response.data.msg);

          // 跳转到home的登录页
          setTimeout(() => {
            location.href = getHost("login");
          }, 500);
          break;
        default:
          if (mes == "platform not match") {
            localStorage.removeItem("Platform");
          }
          Message.error({ message: mes, duration: 3000 });
      }
    } else {
      Message.error({ message: "请求失败", duration: 3000 });
    }
    let isCancel = axios.isCancel(err);
    if (isCancel) {
      return new Promise(() => {});
    }
    return Promise
      .reject
      // (err.response.data && err.response.data.msg) || "网络错误"
      ();
  }
);

export function post(url, data) {
  return axios.post(url, data);
}

// export function get(url, data, otherConfig) {
//   return axios.get(url, { params: data, ...otherConfig });
// }
