import Vue from "vue";
import {
    Dialog,
    Loading,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    Input,
    Message,
    DatePicker,
    Radio,
    Select,
    Option,
    RadioGroup,
    InputNumber,
    Cascader,
    Upload,
    Tooltip,
    Image,
    Tree,
    Pagination,
    Button,
    Progress,
    Carousel,
    CarouselItem,
    Table,
    TableColumn,
    Popover,
    CheckboxGroup,
    Checkbox,
    Form,
    FormItem,
    MessageBox
   
  
} from "element-ui";

Vue.use(Dialog);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Input);
Vue.use(DatePicker);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Select);
Vue.use(Option);
Vue.use(InputNumber);
Vue.use(Cascader);
Vue.use(Upload);
Vue.use(Tree);
Vue.use(Tooltip);
Vue.use(Image);
Vue.use(Pagination);
Vue.use(Button);
Vue.use(Progress);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Popover);
Vue.use(CheckboxGroup);
Vue.use(Checkbox);
Vue.use(Form);
Vue.use(FormItem);

Vue.use(Loading);
Vue.prototype.$message = Message;
Vue.prototype.$confirm = MessageBox.confirm;
