import Vue from 'vue';
import App from './Applist.vue';
import './registerServiceWorker';
import router from './router';
import global_ from './auth'
import { Message } from "element-ui";
// import store from './store';
// import 'element-ui/lib/theme-chalk/index.css';
import "./plugins/element.js";

Vue.prototype.$echarts = window.echarts;

Vue.config.productionTip = false;
global_.then(ele => {
  let access = ele.data.access === 1 || ele.data.manage === 1
  let manage = ele.data.manage === 1
  Vue.prototype.Access = access
  Vue.prototype.Manage = manage
  Vue.prototype.GLOBAL = global_
  if (!access) {
       Message.error('无访问权限，请联系贵公司管理员为您开通')
      let guidMini = location.pathname?.split('/')[1] || ''
      let token = localStorage.getItem("token");
      console.log(guidMini);
      if (guidMini === 'home.html' || guidMini === 'index.html') {
        guidMini = ''
      }
      setTimeout(() => {
      const location_host1 = window.location.host.split('.')
      const active_url1 = location_host1[1] + '.' + location_host1[2]
      if (guidMini) {
        window.location.href = window.location.protocol + '//' + active_url1 + '/' + guidMini + '/#/' + '?pid=' + token
      } else {
        window.location.href = window.location.protocol + '//' + active_url1 + '/#/' + '?pid=' + token
      }
      }, 2000)
  } else {
      new Vue({
        router,
        // store,
        render: (h) => h(App),
      }).$mount('#app');
  }

})

