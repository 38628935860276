<template>
  <div>
     <!-- height: height_h, -->
     <!-- margin:'0 '+ margin+'px' -->
     <div class="header-c" :style="{ 'width': '100vw'}"></div>
     
    <div :style="{ width: '1350px', margin:'0 auto' }">
      <div id="app-list">
        <pgheader :user_info="user_info" />
        <applist :user_auths="user_auths" />
      </div>
    </div>
  </div>
</template>
<script>
import { userinfo } from "./api/getdata";
import applist from "./views/applist.vue";
import pgheader from "./components/header";
export default {
  components: { applist, pgheader },
  watch: {
    $route(to, from) {
      if (from.path === "/applist") {
        document.getElementsByTagName("body")[0].setAttribute("style", "background-color: #04080E");
        document.getElementsByTagName("html")[0].setAttribute("style", "background-color: #04080E");
      }
      if (to.path === "/applist") {
        document.getElementsByTagName("body")[0].setAttribute("style", "background-color: #E3E3E3");
        document.getElementsByTagName("html")[0].setAttribute("style", "background-color: #E3E3E3");
      }
    }
  },
  data() {
    return {
      transformScale: "",
      width: 1350,
      height: 1080,
      height_h: 48,
      margin: 0,
      user_info: {},
      user_auths: {},
    };
  },
  mounted() {
    this.getData();
    this.autoSetScale(); // 进页面先执行一次页面高度和宽度计算然后赋值
    window.addEventListener(
      "resize",
      () => {
        this.autoSetScale();
      },
      false
    );
  },
  methods: {
    getData() {
      userinfo("info").then(ele => {
        // localStorage.setItem("user_auths", "{}");
        this.user_info = ele.data || {};
        this.user_auths = ele.data?.auths || {};
        // localStorage.setItem("user_auths", JSON.stringify(this.user_auths));
      });
    }, // 自动缩放屏幕比例
    autoSetScale() {
      let ratioY = (window.innerHeight / 1080).toFixed(3);
      let ratioX = (window.innerWidth / 1350).toFixed(3);
      let scale = ratioX < ratioY ? ratioX : ratioY;
      
      this.transformScale = "scale(" + scale + "," + scale + ")";
      let width = (1350 * scale).toFixed(3);
      this.width = `${width}`;
      this.height = `${(1080 * scale).toFixed(3)}px`;
      this.height_h = `${(48 * scale).toFixed(5)}px`;
      let margin = (window.innerWidth - width) / 2;
      this.margin = `${margin.toFixed(2)}`;
    }
  },
};
</script>
<style lang="less">
html,
body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  font-family: PingFangSC-Regular, Microsoft YaHei, "\5FAE\8F6F\96C5\9ED1", verdana,
    sans-serif;
  background-color: #e3e3e3;
}
.header-c {
  background-color: #235382;
  position: fixed;
  height: 48px;
}
#app-list {
  width: 1350px;
  // height: 1080px;
  min-height: 100vh;
  transform-origin: 0 0;
  // background-image: url('./assets/bgblock.png');
  background-size: contain;
  transition: all 0.3s linear;
  background-repeat: no-repeat;
  overflow: hidden;
  background: #fff;
  .dlh-edit-icon {
    display: inline-block;
    display: none;
    height: 31px;
    width: 31px;
    background-image: url("./assets/editicon.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    vertical-align: bottom;
    cursor: pointer;
    margin-left: 4px;
    &:hover {
      opacity: 0.85;
    }
  }
  .flex-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .dlh-red-btn {
    color: #fff;
    background-color: #c5545a;
    cursor: pointer;
    &:hover {
      background-color: #ff8a8a;
    }
    &:active {
      background-color: #d1454e;
    }
    &.disabled-btn {
      opacity: 0.45;
    }
  }
  .un-select {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10+ and Edge */
    user-select: none; /* Standard syntax */
  }
  .two-line {
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .one-line {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.pro_title {
  color: #fff;
  .el-dialog {
    width: 1200px;
  }
  .el-dialog__header {
    padding: 16px 18px 16px 24px;
    background-color: #2c323c;
    color: #fff;
  }
  .el-dialog__headerbtn .el-dialog__close {
    color: #fff;
  }
  .el-dialog__title {
    line-height: 22px;
    font-size: 16px;
    color: #fff;
  }
  .el-dialog__body {
    padding: 0;
  }
}
.click-btn {
  &:hover {
    opacity: 0.85;
  }
  &:active {
    opacity: 0.9;
  }
}
.dlh-primary-btn {
  background: #0a6ed1;
  padding: 0 14px;
  height: 32px;
  min-width: 65px;
  line-height: 32px;
  color: #fff;
  box-sizing: border-box;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  &:hover {
    background: #2f8cde;
  }
  &:active {
    background: #0050ab;
  }
}

</style>
