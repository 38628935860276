<template>
  <header class="header" :style="{ width: width_w + 'px' }">
    <div class="main-container">
      <div class="head_left">
        <!-- <div class="three-line-box">
          <i class="three-line"></i>
        </div>
        <span class="b-block"></span>
        <div>
          <img
            style="height:48px;object-fit: contain;min-width:48px;margin-right:4px;vertical-align: top;"
            :src="user_info.logo"
            alt=""
          />
          <span style="font-size:24px;font-weight:700">{{ user_info.company }}</span>
        </div> -->
        <img src="../assets/headlogo1.png" alt="" />
        <div class="p1">可视大屏</div>
        <span></span>
        <div class="p2">{{ user_info.company }}</div>
      </div>
      <!-- <ul class="title-tab not-select" style="width:132px;min-width: 132px;">

        <li style="width:132px;cursor: default;font-weight:700;">
          <i class="pmp-icon" :style="{ 'background-image': 'url(' + pmp_icon + ')' }"></i> 可视大屏
        </li>
        <li :class="{active:now_active ===index,liclass:true}" v-for="(item,index) in tab_link" :key="index" @click="goPage(index)">{{item.name}}</li>
      </ul> -->
      <div class="user-info">
        <div class="name">{{ user_info.name }}</div>
        <el-dropdown @command="handleCommand">
          <span
            class="el-dropdown-link"
            style="
              height: 48px;
              width: 42px;
              display: flex;
              align-items: center;
              justify-content: center;
              text-align: center;
              background: #0a6ed1;
            "
          >
            <img :src="user_info.image || require('../assets/defaultimg.png')" alt="" />
            <!-- <i v-else class="el-icon-user-solid"></i> -->
            <!-- <img v-if="user_info.image" :src="user_info.image" alt=""><i  v-else class="el-icon-user-solid" style="margin-right:5px"></i>{{user_info.name}} -->
          </span>
          <el-dropdown-menu class="drop" slot="dropdown">
            <el-dropdown-item command="check">
              <i class="iconstyle ele-icon-qiehuan"></i>
              切换系统</el-dropdown-item
            >
            <el-dropdown-item v-if="has_manage" command="company">
              <i class="iconstyle ele-icon-gongsi"></i>
              公司管理</el-dropdown-item
            >
            <el-dropdown-item command="person">
              <i class="iconstyle ele-icon-geren"></i>
              个人设置</el-dropdown-item
            >
            <el-dropdown-item class="last" command="logout">
              <i class="iconstyle ele-icon-tuichu"></i>
              退出登录</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </header>
</template>
<script>
import { getHost } from "@/constants/navigationJump.js";
import { logout, userinfo } from "../api/getdata";
export default {
  data() {
    return {
      dialogVisible: false,
      has_manage: false,
      tab_link: [
        {
          name: "项目看板",
          url: "/applist"
        }
      ],
      active_list: {
        "/": 0,
        "/crmcenter": 1,
        "/potentialcus": 1,
        "/stakeholder": 1,
        "/accomplished": 1,
        "/crminfo": 0
      },
      pmp_icon: require("../assets/pmpicon.svg")
      //   title_info: {}
    };
  },
  props: {
    user_info: {},
    width_w: "1350px"
  },
  computed: {
    now_active() {
      return this.active_list[this.$route.path];
    }
  },
  methods: {
    handleCommand(command) {
      if (command === "logout") {
        logout("logout").then(ele => {
          localStorage.removeItem("token");

          // 跳转到home的登录页
          const url = getHost("login");

          window.location.href = url;
        });
      } else if (command === "check") {
        // 跳转home首页
        const url = getHost("home");

        window.location.href = url;
      } else if (command === "company") {
        // 跳转到公司管理
        const url = getHost("companyManagement");

        window.open(url, "_blank");
      } else if (command === "person") {
        // 跳转到个人中心
        const url = getHost("personalSetting");

        window.open(url, "_blank");
      }
    },
    goPersion() {
      this.dialogVisible = false;
      this.$router.push("/persioninfo");
    },
    goPage(index) {
      document.body.scrollIntoView();
      this.$router.push(this.tab_link[index].url);
    }
  },
  mounted() {
    userinfo("info").then(ele => {
      this.has_manage = ele.data.has_manage || false;
      console.log(this.has_manage);
    });
  }
};
</script>
<style lang="less">
header.header {
  width: 100%;
  height: 48px;
  line-height: 48px;
  background-color: #0a6ed1;
  color: #fff;
  position: fixed;
  left: 0;
  z-index: 2000;
  overflow: hidden;
  .main-container {
    width: 1350px;
    margin: 0 auto;
    position: relative;
    display: flex;
    background-color: #0a6ed1;
    .head_left {
      position: absolute;
      display: flex;
      align-items: center;
      padding-left: 10px;
      img {
        width: 32px;
        height: 32px;
        opacity: 1;
        background: #ffffff;
        margin-right: 10px;
      }
      span {
        height: 13px;
        border-left: 1px solid rgba(255, 255, 255, 0.85);
        margin: 0 13px;
      }
      > div {
        opacity: 1;

        font-size: 16px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        text-align: LEFT;
        color: #ffffff;
      }
      .p2 {
        font-weight: 400;
        text-align: RIGHT;

        color: rgba(255, 255, 255, 0.85);
      }
    }
  }
  .user-info {
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
    .name {
      width: 120px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 16px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      text-align: RIGHT;
      color: #ffffff;
    }
    // img{
    //     height: 22px;
    //     width: 22px;
    //     object-fit: contain;
    //     vertical-align: middle;
    //     background-color: #fff;
    //     margin-right: 8px;
    //     border-radius: 100%;
    //     overflow: hidden;
    //     object-fit: fill;
    // }
    img {
      display: inline-block;
      height: 32px;
      width: 32px;
      object-fit: contain;
      vertical-align: middle;
      background-color: #fff;
      // margin-right: 8px;
      border-radius: 100%;
      overflow: hidden;
      object-fit: fill;
    }
    .el-dropdown-link {
      color: #fff;
      cursor: pointer;
      img {
        width: 22px;
        height: 22px;
      }
    }
  }
  .title-tab {
    margin: 0 auto;
    display: flex;
    background-color: #296faf;
    min-width: 809px;
    padding: 0;
    margin-left: 308px;
    li {
      width: 135px;
      text-align: center;
      cursor: pointer;
      position: relative;
      &.liclass:hover,
      &.active {
        background-color: #235382;
        font-weight: 900;
      }
      &.active {
        &::after {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          right: 0px;
          border: 4px solid transparent;
          border-right-color: #fff;
          border-top-color: #fff;
        }
      }
    }
    .pmp-icon {
      display: inline-block;
      height: 22px;
      width: 24px;
      vertical-align: middle;
      position: relative;
      left: -5px;
      top: -2px;
      margin-left: 12px;
    }
  }
  .three-line-box {
    display: inline-block;
    height: 100%;
    // width: 48px;
    text-align: center;
    background-color: #0a6ed1;
    i {
      display: inline-block;
      height: 17px;
      width: 18px;
      margin: 16px;
      background-image: url("../assets/threel.svg");
    }
  }
}
ul,
li {
  list-style-type: none;
}

div.v-modal {
  opacity: 0.45;
}
.user-dialog {
  .el-dialog__header {
    padding: 0 20px;
  }
  .el-dialog__body {
    padding-bottom: 0;
  }
  .el-dialog__footer {
    padding-top: 2px;
    padding-bottom: 24px;
  }
  .tip-img {
    text-align: center;
    color: rgba(30, 36, 58, 0.85);
    font-size: 14px;
    img {
      display: block;
      height: 175px;
      width: 235px;
      margin: 0 auto;
    }
    div {
      margin-top: 6px;
    }
  }
}

.iconstyle {
  width: 14px;
  height: 14px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: inline-block;
  vertical-align: middle;
}
.ele-icon-qiehuan {
  background-image: url("../assets/qiehuan.png");
}
.ele-icon-gongsi {
  background-image: url("../assets/gongsi.png");
}
.ele-icon-geren {
  background-image: url("../assets/geren.png");
}
.ele-icon-tuichu {
  background-image: url("../assets/tuchu.png");
}
.el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
  color: #606266;
  background-color: rgba(243, 243, 243);
  &.el-dropdown-menu__item::before {
    position: absolute;
    left: 0;
    top: 5px;
    content: "";
    width: 3px;
    height: 14px;
    opacity: 1;
    background: #0a6ed1;
    border-radius: 2px;
  }
}
.el-dropdown-menu {
  padding: 9px 10px 0 !important;
}
.drop .last::after {
  position: absolute;
  left: 12px;
  top: -8px;
  content: "";
  width: 80%;
  height: 1px;
  opacity: 1;
  background: rgba(255, 255, 255, 0.45);
  box-shadow: 0px 1px 0px 0px rgba(106, 109, 112, 0.25);
}
.drop .last {
  margin-top: 16px;
}
.el-dropdown-menu__item {
  position: relative;
  padding: 0 10px;
  // height: 24px;
  margin-bottom: 8px;
  line-height: 24px;
}
</style>
